import React from 'react'
import '../../../App.css'

type CreateHeaderProps = {
  ID: string
}

export const CreateHeader = ({ ID }: CreateHeaderProps) => {
  return (
    <div className='bg-center bg-cover flex flex-col gap-8 items-center p-8' id={ID}>
      <p className='mx-auto text-center text-white font-bold text-[4vw] mssn:text-[2.77vw]'>
        Implement new technology use cases in just a few clicks with STAR
      </p>
      <p
        id='subheadline'
        className='leading-tight py-6 text-[3.5vw] sm:text-base xl:text-xl break-words'
      >
        STAR combines advanced conversational AI, an intuitive 3D spatial computing engine, and
        robust security, delivering cutting-edge technology with ease of use.
      </p>
      <div className='externalLinks flex gap-8 mx-auto'>
        <a
          href='mailto:STAR.helpdesk@accenture.com'
          target='_blank'
          className='bg-[#A100FF] hover:bg-white hover:text-[#A100FF] text-white font-bold px-3 pb-1 pt-0.5 mt-0.5'
        >
          Contact Now
        </a>
      </div>
    </div>
  )
}
